import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

export default function Button({
  href,
  className,
  children,
  ...restProps
}) {
  const props = {
    href,
    className: classNames(styles.button, className, {
      buttonLink: href
    }),
    ...restProps
  };

  return (
    <React.Fragment>
      {href ?
        <a {...props}>{children}</a>
      :
        <button {...props}>{children}</button>
      }
    </React.Fragment>
  );
}