import { useState, useCallback } from 'react';

export default function useModal(callback) {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => {
    document.activeElement.blur();
    setIsOpen(true);

    if (callback) {
      callback(true);
    }
  }, [callback]);

  const close = useCallback(args => {
    setIsOpen(false);

    if (callback) {
      callback(false);
    }
  }, [callback]);

  return [isOpen, open, close];
}