import React from 'react';
import { Content } from '@layout';
import Logo from '@images/logo.svg';
import Button from '@components/Button';
import Modal, { Title, useModal } from '@components/Modal';
import DemoForm from '@components/DemoForm';
import styles from './Header.module.scss';

export default function Header({ siteTitle, onModalChange }) {
  const [isOpen, openModal, closeModal] = useModal(onModalChange);

  return (
    <header className={styles.header}>
      <Content className={styles.content}>
        <img
          src={Logo}
          alt={siteTitle}
          className={styles.logo}
        />

        <Button onClick={openModal}>
          Request a Demo
        </Button>
      </Content>
      <Modal
        isOpen={isOpen}
        maxWidth={500}
        close={closeModal}
      >
        <DemoForm>
          <Title>Request a Demo</Title>
          <p>Want to learn more? Schedule a demo to see out {siteTitle} can help your company.</p>
        </DemoForm>
      </Modal>
    </header>
  );
}