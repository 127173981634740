import React from 'react';
import styles from './Text.module.scss';
import classNames from 'classnames';

export default function Text({
  name,
  label,
  className,
  inputClassName,
  onChange,
  ...restProps
}) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {label &&
        <label className={styles.label}>
          {label}
        </label>
      }
      <input
        name={name}
        className={classNames(styles.input, inputClassName)}
        onChange={e => onChange(name, e.target.value)}
        {...restProps}
      />
    </div>
  );
}