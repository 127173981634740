import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import 'focus-visible';
import Header from '../Header';
import '@fonts/inter.scss';
import '@styles/general.scss';
import styles from './Layout.module.scss';
import classNames from 'classnames';

export default function Layout({ children, onModalChange }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <Header
        siteTitle={data.site.siteMetadata.title}
        onModalChange={onModalChange}
      />
      <main>{children}</main>
    </React.Fragment>
  );
}

export function Content({
  maxWidth,
  style = {},
  className,
  type = 'div',
  ...restProps
}) {
  const props = {
    className: classNames(styles.content, className),
    style:  { maxWidth, ...style },
    ...restProps
  };

  return (
    <React.Fragment>
      {type === 'section' ?
        <section {...props} />
      :
        <div {...props} />
      }
    </React.Fragment>
  );
}