import React, { useState, useCallback } from 'react';
import Button from '@components/Button';
import Text from '@input/Text';
import styles from './DemoForm.module.scss';

const formName = 'Request a Demo';

export default function DemoForm({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [state, setState] = useState({
    name: '',
    email: '',
    company: '',
    website: ''
  });

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    const submitForm = async () => {
      setIsLoading(true);

      try {
        const response = await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': formName, ...state })
        });

        console.log(response);
        setSubmitted(true);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    
    submitForm();
  }, [state]);

  const handleChange = useCallback((name, value) => {
    setState(state => ({ ...state, [name]: value }));
  }, []);

  return (
    <div className={styles.wrapper}>
      {!isLoading ?
        <React.Fragment>
          {submitted ?
            'Successful submission'
          :
            <form
              name="Request a Demo"
              data-netlify="true"
              method="post"
              encType="multipart/form-data"
              action="/"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value={formName} />
              {children}
              <Text
                name="name"
                label="Name"
                value={state.name}
                onChange={handleChange}
              />

              <Text
                name="email"
                label="Email Address"
                type="email"
                value={state.email}
                onChange={handleChange}
              />

              <Text
                name="company"
                label="Company Name"
                value={state.company}
                onChange={handleChange}
              />

              <Text
                name="website"
                value={state.website}
                label="Company Website"
                onChange={handleChange}
              />

              <Button type="submit">Request a Demo</Button>
            </form>
          }
        </React.Fragment>
      :
        'Loading...'
      }
    </div>
  );
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}