import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.scss';
import useEscapeKey from '@hooks/useEscapeKey';
import useClickOutside from '@hooks/useClickOutside';
import classNames from 'classnames';
import { RemoveScroll } from 'react-remove-scroll';
import FocusLock from 'react-focus-lock';

const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;

export default function Modal({
  isOpen,
  close,
  children,
  title,
  maxWidth,
  style = {},
  usePortal = true
}) {
  const [el] = useState(() => {
    return typeof document !== 'undefined' ? document.createElement('div') : null;
  });

  useEffect(() => {
    portalRoot.appendChild(el);
    return () => portalRoot.removeChild(el);
  }, [el]);

  useEscapeKey(() => {
    if (!document.activeElement?.classList.contains('focus-visible')) {
      close();
    }
  });

  const modalRef = useRef(null);
  useClickOutside(modalRef, close);

  const modal = (
    <div
      className={classNames(styles.wrapper, {
        [styles.open]: isOpen
      })}
    >
      <RemoveScroll enabled={isOpen}>
        <FocusLock
          disabled={!isOpen}
          autoFocus={false}
          persistentFocus={false}
        >
          <div
            ref={modalRef}
            className={styles.modal}
            role="dialog"
            style={{ ...style, maxWidth }}
          > 
            {title &&
              <Title>{title}</Title>
            }
            {children}
          </div>
        </FocusLock>
      </RemoveScroll>
    </div>
  );

  if (usePortal) {
    return !el ? modal : createPortal(modal, el);
  }

  return modal;
}

export function Title({ children }) {
  return <h2 className={styles.title}>{children}</h2>;
}

export { default as useModal } from '@hooks/useModal';